import React from 'react';
import { graphql } from 'gatsby'
import loadable from '@loadable/component'

/* Layouts */
import BaseLayout from 'layouts/base.layout'

/* Components */
import SEO from "components/seo"
import StageComponent from 'components/stage'
import LoadingIndicator from 'components/loading-indicator'
import ImageTextComponent from 'components/image-text'

/* Lazy Loading */
const BlogPreviewComponent = loadable(() => import('components/teaser/blogPosts/blog-preview.component'))
const LinklistComponent = loadable(() => import('components/linklist/link-list.component'))

const IndexPage = (props) => {
  return (
    <BaseLayout>
      <SEO title="Home" url={ props.location.href } />
      <StageComponent props={ props.data.stage } />
      <ImageTextComponent props={ props.data.imageText } />
      <div className="bg-white">
        <BlogPreviewComponent fallback={<LoadingIndicator />} props={ props.data.blogPreview } />
        <LinklistComponent fallback={<LoadingIndicator />}/>
      </div>
    </BaseLayout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    stage: contentfulStage(page: {eq: "Startseite"}) {
      title
      subtitle
    }

    blogPreview: allContentfulBlogPosts (
      sort: {
        fields: publishedDate
        order: DESC
      }
      limit: 3
    ) {
      ...blogPreviewQuery
    }

    imageText: allContentfulImageTextModule (
      filter: {page: {eq: "Startseite"}}
    ) {
      ...imageTextQuery
    }
  }
`
